import 'bootstrap';
import 'parsleyjs';

new WOW().init();
IWillVoteLocationSearch.init();

window.addEventListener(
    "load",
    function () {
      var video = document.querySelector("#background-figure video");
      function checkLoad() {
        if (video && video.readyState === 4) {
          $("#background-figure video").animate({ opacity: 1 }, 2000);
          $("#background-figure picture").animate({ opacity: 0 }, 2000);
          $("#background-figure picture img").animate({ opacity: 0 }, 2000);

          video.play();
        } else {
          setTimeout(checkLoad, 100);
        }
      }
  
      checkLoad();
    },
    false
  );